import React from 'react'
import { Icon } from 'antd'
import LeftColumn from './LeftColumn'
import RightColumn from './RightColumn'
import Footer from './Footer'
import './index.css'

class CV extends React.Component {
  render () {
    return (
      <div>
        <div className='Author'>Daniel Han</div>
        <div className='Subtitle'>QA Manager, Team Lead, Test Automation Specialist</div>
        <div id='cv' className='CV'>
          <LeftColumn/>
          <RightColumn/>
        </div>
        <div className='address'>
          <Footer />
        </div>
        <div className='floatingIcon' title='Download this CV!'>
          <div><a href='/static/Daniel-Han-CV.pdf'><Icon type="download" style={{ fontSize: '24px', color: 'white' }}/></a></div>
        </div>
      </div>
    )
  }
}

export default CV
